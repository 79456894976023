.wrapper {
    padding: 2rem 2rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    box-shadow: 0px 1px 2px rgba(0, 28, 36, 0.3);
    background-color: white;
}

.img {
    height: 4.5rem;
}
